// @noflow

// Аналитика для Яндекса
export const pushYmAnalytics = (value: string) => {
  const eventLabel = value.split('#')[0]
  const eventCategory = value.split('#')[1]

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'MEvent',
    eventCategory: eventCategory,
    eventAction: 'success',
    eventLabel: eventLabel,
  })

  if (window.ym) {
    window.ym(1508995, 'reachGoal', eventLabel)
  }

  return true
}
