// @flow

import { FoxfordService } from 'services/foxford'

import type { UserInterests } from '@foxford/foxford-js-sdk'

type CartItem = {
  resource_id: number,
  cart_item_type:
    | 'entire_course'
    | 'month_access'
    | 'standard_access'
    | 'teacher_certificate_delivery'
    | 'coach_lesson'
    | 'product'
    | 'individual_pack'
    | 'product_pack'
    | 'bank_card_save'
    | 'recurrent'
    | 'olympiad'
    | 'course_installment'
    | 'externship_contract_payment',
}

export const pendingActions = {
  /**
   * Записывает пользователя на указанный курс.
   * @param {number} id - ID курса
   * @param {number|undefined} groupTemplateId - ID мини-группы
   */
  addCourse: async (id: number, groupTemplateId?: number) => {
    await FoxfordService.api.post(`/api/user/pending_action`, {
      action: 'add_course',
      id,
      group_template_id: groupTemplateId,
    })
  },

  /**
   * Добавляет указанный элемент в корзину пользователя.
   * @param {string} cartItemType - тип элемента (entire_course, month_access, standard_access, teacher_certificate_delivery, coach_lesson, product, individual_pack, product_pack, bank_card_save, recurrent, olympiad, externship_contract_payment)
   * @param {string} productType - тип продукта (course, course_installment, coach_product, game, product_pack, product, olympiad, recurrent)
   * @param {number} resourceId - ID элемента
   */
  addCartItem: async (cartItemType: string, productType: string, resourceId: number) => {
    await FoxfordService.api.post(`/api/user/pending_action`, {
      action: 'add_cart_item',
      cart_item_type: cartItemType,
      product_type: productType,
      resource_id: resourceId,
    })
  },

  /**
   * Теперь поддерживает отправку промокода и отправку сразу нескольких курсов за 1 запрос. ProductType больше не требуется.
   */
  addCartItemUpdate: async (items: CartItem[], promoCode?: string) => {
    await FoxfordService.api.post(`/api/user/pending_action`, {
      pending_action: {
        action: 'add_cart_item',
        cart_items: items,
        promo_code: promoCode,
      },
    })
  },

  /**
   * Добавляет тег пользователю.
   * @param {number} id ID тега
   */
  addTag: async (id: number) => {
    await FoxfordService.api.post(`/api/user/pending_action`, {
      action: 'add_tag',
      id,
    })
  },

  /**
   * Изменяет подписку пользователя.
   * @param {string} topic - имя подписки (materials, promos, reminders, media)
   * @param {boolean} state - состояние подписки
   */
  changeSubscription: async (topic: string, state: boolean) => {
    await FoxfordService.api.post(`/api/user/pending_action`, {
      action: 'change_subscription',
      topic,
      state,
    })
  },

  /**
   * Добавляет интересы пользователя.
   * @param {$Shape<UserInterests>} data - данные интересов пользователя
   *   - disciplineId - выбранная дисциплина (https://foxford.ru/api/disciplines)
   *   - levelId - цель пользователя (https://foxford.ru/api/levels)
   *   - gradeId - id класса пользователя (https://foxford.ru/api/grades)
   *   - reason - причина получения интереса (для проектных лендов 'project_landing_cta_clicked')
   */

  addUserInterests: async (data: $Shape<UserInterests>) => {
    await FoxfordService.api.post(`/api/user/pending_action`, {
      action: 'add_interest',
      grade_id: data.gradeId,
      reason: data.reason,
      level_id: data.levelId,
      discipline_id: data.disciplineId,
      business_unit_abbrs: data.businessUnitAbbrs,
    })
  },
}
