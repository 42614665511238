// @flow

// TODO: при замене на реализацию от Фокса не забыть удалить эти модули через npm.
import intlTelInput from 'intl-tel-input'
import $ from 'jquery'

import { FoxfordService } from 'services/foxford'
import { FASTDEV_USER_EVENTS } from 'services/user/data'

import { loadScript } from '../load-script'
import { toggleSubmitButtons } from '../toggle-submit-buttons'

import 'jquery.maskedinput/src/jquery.maskedinput'
import 'intl-tel-input/build/css/intlTelInput.min.css'
import './index.css'

import type { User } from '@foxford/foxford-js-sdk'

type NumberFormat = {
  E164: 0,
  INTERNATIONAL: 1,
  NATIONAL: 2,
  RFC3966: 3,
}

type NumberType = {
  FIXED_LINE: 0,
  MOBILE: 1,
  FIXED_LINE_OR_MOBILE: 2,
  TOLL_FREE: 3,
  PREMIUM_RATE: 4,
  SHARED_COST: 5,
  VOIP: 6,
  PERSONAL_NUMBER: 7,
  PAGER: 8,
  UAN: 9,
  VOICEMAIL: 10,
}

type ValidationError = {
  IS_POSSIBLE: 0,
  INVALID_COUNTRY_CODE: 1,
  TOO_SHORT: 2,
  TOO_LONG: 3,
  NOT_A_NUMBER: 4,
}

declare var intlTelInputUtils: {
  formatNumber(number: string, countryCode: string, format: $Values<NumberFormat>): string,
  getExampleNumber(countryCode: string, isNational: boolean, numberType: $Values<NumberType>): string,
  getNumberType(number: string, countryCode: string): $Values<NumberType>,
  getValidationError(number: string, countryCode: string): string,
  isValidNumber(number: string, countryCode: string): string,
  numberFormat: NumberFormat,
  numberType: NumberType,
  validationError: ValidationError,
}

/**
 * Отображает ошибку инпута.
 * @param {JQuery} $input Инпут.
 */
export const showPhoneError = ($input: JQuery) => {
  $input.addClass('input--error')
  $input.siblings('.input__error-text').removeClass('hide')
}

/**
 * Устанавливает маску телефона для конкретного инпута из плейсхолдера.
 * @param {JQuery} $phoneInput Инпут.
 */
const setPhoneMask = ($phoneInput: JQuery, phoneMask: string) => {
  $phoneInput.trigger('unmask').mask(phoneMask, {
    autoclear: false,
  })
}

/**
 * Инициализирует выбор страны в инпуте.
 * @param {HTMLInputElement} input Инпут.
 * @param {string} phone Номер телефона.
 */
const initPhoneMaskForInput = (input: HTMLInputElement, phone: string, needValidate: boolean) => {
  const $phoneInput = $(input)

  const marginBottom = $phoneInput.css('margin-bottom')

  /**
   * В данной библиотеке utilsScripts грузятся только после window onload
   * Сделано с той целью, чтобы не тормозить загрузку страницы, ибо скрипт весит больше 200кб
   * Поэтому пока что тут отключаю и загружаю скрипт вручную
   */
  const iti = intlTelInput(input, {
    initialCountry: 'ru',
    nationalMode: false,
    placeholderNumberType: 'MOBILE',
    preferredCountries: ['ru', 'ua', 'kz', 'by', 'kg', 'uz'],
    separateDialCode: false,
    // utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/22.0.2/js/utils.min.js',
  })

  $phoneInput.on('countrychange', () => {
    const exampeNumber = intlTelInputUtils.getExampleNumber(
      iti.selectedCountryData.iso2,
      false,
      intlTelInputUtils.numberFormat.INTERNATIONAL
    )

    setPhoneMask($phoneInput, exampeNumber.replace(/[0-9]/g, '9'))

    $phoneInput.trigger('focus.mask')
  })

  let currentCountry = iti.selectedCountryData.iso2

  $phoneInput.on('open:countrydropdown', () => {
    currentCountry = iti.selectedCountryData.iso2
  })

  $phoneInput.on('close:countrydropdown', () => {
    if (currentCountry !== iti.selectedCountryData.iso2) {
      $phoneInput.val(`+${iti.selectedCountryData.dialCode}`)
    }
  })

  $phoneInput.on('focus', () => {
    if (!$phoneInput.val()) {
      $phoneInput.val(`+${iti.selectedCountryData.dialCode}`)
    }
  })

  const $form = $phoneInput.closest('form')

  $phoneInput.on('blur', () => {
    if (needValidate) {
      const isInvalidNumber = !iti.isValidNumber()

      toggleSubmitButtons($form, isInvalidNumber)

      if (isInvalidNumber) {
        showPhoneError($phoneInput)
      }
    }

    const user: User = FoxfordService.user.getCurrentUser()
    const phoneInInput = String($phoneInput.val()).replace(/[-+ ]/g, '')

    if (user.phone === phoneInInput && user.phoneConfirmed) {
      $('input[data-name="Phone"], input[data-name="phone"]').addClass('is-confirmed')
    } else {
      $('input[data-name="Phone"], input[data-name="phone"]').removeClass('is-confirmed')
    }
  })

  const $iti = $phoneInput.parent('.iti')

  // When the plugin loads for the first time, we have to trigger the "countrychange" event manually,
  // but after making sure that the plugin is fully loaded by associating handler to the promise of the
  // plugin instance.
  iti.promise.then(() => {
    if (phone) {
      iti.setNumber(`+${phone}`)
    }

    const exampeNumber = intlTelInputUtils.getExampleNumber(
      iti.selectedCountryData.iso2,
      false,
      intlTelInputUtils.numberFormat.INTERNATIONAL
    )

    setPhoneMask($phoneInput, exampeNumber.replace(/[0-9]/g, '9'))

    $iti
      .find('ul.iti__country-list')
      .css('width', $phoneInput.css('width'))
      .css('font-size', $phoneInput.css('font-size'))

    $iti.find('.iti__selected-flag').css('font-size', $phoneInput.css('font-size'))

    // const $errorText = $phoneInput.siblings('.input__error-text')

    // if ($errorText.length === 0) {
    //   // создадим свой
    //   $phoneInput.after('<div class="input__error-text hide">ошибка номера, введите правильный номер</div>')
    // } else {
    //   $phoneInput.after($errorText)
    // }
  })

  $iti.css('margin-bottom', marginBottom).css('color', $phoneInput.css('color'))
}

/**
 * Устанавливает состояние ошибки (по стандартным классам) у инпута телефона.
 * @param {EventTarget} htmlInput Инпут.
 */
const setPhoneInputToErrorState = (htmlInput: EventTarget) => {
  const input = $(htmlInput)

  if (input.hasClass('input--error')) {
    input.removeClass('input--error')
    input.siblings('.input__error-text').addClass('hide')
  }
}

/**
 * Устанавливает маску и выбор страны для всех инпутов на странице по переданному селектору.
 * @param {string} phoneInputSelector Селектор.
 */
export const initPhoneMaskModule = async (
  phoneInputSelector: string = 'input[type="tel"], input[data-name="Phone"], input[data-name="phone"]',
  needValidate: boolean = true
): Promise<void> => {
  // Пришлось переместить сюда загрузку скрипта из utilsScript
  await loadScript('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/22.0.2/js/utils.min.js')

  // сделано так т.к. метод iti.setNumber(`+${phone}`) должен сработать после стандартного onUserSet

  let isUserSetFired = false

  const setUserEventFired = () => {
    isUserSetFired = true

    window.removeEventListener(FASTDEV_USER_EVENTS.SET_USER, setUserEventFired)
  }

  window.addEventListener(FASTDEV_USER_EVENTS.SET_USER, setUserEventFired)

  // таймаут на случай, если событие FASTDEV_USER_EVENTS.SET_USER не сработает
  const timeoutID = setTimeout(setUserEventFired, 10000)

  const intervalID = setInterval(() => {
    if (isUserSetFired) {
      clearInterval(intervalID)
      clearTimeout(timeoutID)

      const $phoneInputs = $(phoneInputSelector).filter(':not([data-nomask])')

      if ($phoneInputs.length === 0) {
        return
      }

      $phoneInputs.each<HTMLInputElement>((_, element) =>
        initPhoneMaskForInput(element, String(element.value), needValidate)
      )
    }
  }, 500)

  $(phoneInputSelector).on('keydown', ({ currentTarget }) => {
    $(currentTarget).removeClass('is-confirmed')
    setPhoneInputToErrorState(currentTarget)
  })

  // // Если у юзера телефон подтвержден, то дизейблим инпут и отображаем это
  // if (user.phoneConfirmed) {
  //   console.log('телефон подтвержден')
  //   $phoneInputs.each<HTMLInputElement>((_, element) => $(element).addClass('is-confirmed')).prop('disabled', true)
  // } else {
  //   console.log('телефон не подтвержден')
  //   $phoneInputs.each<HTMLInputElement>((_, element) =>
  //     initPhoneMaskForInput(element, String(element.value), needValidate)
  //   )

  //   $(phoneInputSelector).on('keydown', ({ currentTarget }) => setPhoneInputToErrorState(currentTarget))
  // }
}

let widthSet = false
/**
 * Изменяет ширину списка стран.
 */
export const changeWidthListContry = (formSelector: string) => {
  if (widthSet) {
    return
  }

  // таймаут, т.к. даем время браузеру отобразить модалку и рассчитать размеры инпута телефона,
  // чтобы потом это значение установить в выпадающий список
  setTimeout(() => {
    const $countryList = $(`${formSelector} ul.iti__country-list`)
    const $phoneInput = $(`${formSelector} input[data-name="Phone"]`)
    $countryList.css('width', $phoneInput.css('width'))
    widthSet = true
  }, 200)
}
