// @flow

export const loadScript = (url: string, async: boolean = true): Promise<{ status: boolean, message?: string }> =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      resolve({ status: true, message: `uploaded earlier` })
      return
    }

    try {
      const script = document.createElement('script')
      script.src = url
      script.type = 'text/javascript'
      script.async = async

      script.addEventListener('load', () => {
        resolve({ status: true, message: `ready` })
      })

      script.addEventListener('error', () => {
        reject({
          status: false,
          message: `Ошибка при загрузке скрипта ${url}`,
        })
      })

      document.body?.appendChild(script)
    } catch (error) {
      reject({
        status: false,
        message: `Ошибка при создании скрипта: ${error.message}`,
      })
    }
  })
