// @flow

import { FoxfordService } from 'services/foxford'

import { HOST } from './constants/foxford'

/**
 * Пуш ивент + отправляем в личный кабинет учителя
 * @param {string} label - название лейбла
 */
export const teacherInvite = async (label: string = 'invite') => {
  await FoxfordService.user.pushEvent({ action: 'conversion', label: label })

  window.location.assign(`${HOST}/teacher-dashboard/school_classes`)
}
