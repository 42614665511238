/* eslint-disable prefer-arrow/prefer-arrow-functions */
// @flow

import type { Action, EventCategory } from './types'

type TupleArgs<T> =
  | [Action]
  | [Action, string]
  | [Action, string, string]
  | [Action, string, T]
  | [Action, string, string, T]

// eslint-disable-next-line max-params
function debugV2<T>(
  eventType: Action,
  eventCategory: string,
  eventValue: string,
  eventLabel: string,
  eventDataObject: T | void
): void {
  if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_BETA === 'true') {
    const tableData = {
      event_category: eventCategory ?? null,
      event_label: eventLabel ?? null,
      event_type: eventType ?? null,
      event_value: eventValue ?? null,
    }

    console.groupCollapsed(
      `%c📊 Analytic action: ${eventType} 🠖 ${
        eventValue === eventCategory ? eventCategory : `${eventValue} (${eventCategory})`
      }`,
      'background: linear-gradient(to top, #682fc4, #8a51e6); padding: 8px; font-size: 12px; color: #ffffff; border-radius: 3px;'
    )

    console.log(
      '%cAnalytic data:',
      'font-weight: bold; padding: 3px; background: linear-gradient(to top, #4b79a1, #4b79a1);border-radius: 3px;'
    )

    console.table(tableData)

    if (eventDataObject !== null && typeof eventDataObject === 'object' && Object.keys(eventDataObject).length > 0) {
      console.log(
        '%cAdditional event_data:',
        'font-weight: bold; padding: 3px; background: linear-gradient(to top, #ff6922, #ff6922);border-radius: 3px;'
      )
      console.table((eventDataObject: any))
    }

    console.groupEnd()
  }
}

/**
 * Трэкинг действий пользователя для аналитики
 *
 * @param {EventCategory} eventCategory категория аналитики
 * @returns {function(...args): void}
 */
export function fireAnalyticsV2(
  eventCategory: EventCategory
): ((eventType: Action) => void) &
  ((eventType: Action, eventValue: string) => void) &
  (<D>(eventType: Action, eventValue: string, eventDataObject: D) => void) &
  ((eventType: Action, eventValue: string, eventLabel: string) => void) &
  (<D>(eventType: Action, eventValue: string, eventLabel: string, eventDataObject: D) => void) {
  if (typeof eventCategory !== 'string') {
    throw new TypeError('Attention!!! Check your call of FireAnalytics function. Event category is invalid.')
  }

  const fn: ((eventType: Action) => void) &
    ((eventType: Action, eventValue: string) => void) &
    ((eventType: Action, eventValue: string, eventLabel: string) => void) &
    (<D>(eventType: Action, eventValue: string, eventDataObject: D) => void) &
    (<D>(eventType: Action, eventValue: string, eventLabel: string, eventDataObject: D) => void) = function <T>(
    ...args: TupleArgs<T>
  ) {
    if (!args.length) {
      throw new TypeError('Event type must be specified')
    }
    if (args.length > 4) {
      throw new TypeError('Too many arguments')
    }

    let eventValue: string = ''
    let eventLabel: string = ''
    let eventDataObject: T | void

    const [eventType] = args

    if (args.length === 2) {
      eventValue = args[1] ?? eventCategory
    }

    if (args.length === 3) {
      eventValue = args[1] ?? eventCategory
      if (typeof args[2] === 'string') {
        eventLabel = args[2]
      } else {
        eventDataObject = args[2]
      }
    }

    if (args.length === 4) {
      eventValue = args[1] ?? eventCategory
      eventLabel = args[2]
      eventDataObject = args[3]
    }

    debugV2<T>(eventType, eventCategory, eventValue, eventLabel, eventDataObject)

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ event: null, payload: null })

    window.dataLayer?.push({
      event: 'analytics/TRACK_ANALYTICS_DATA_V2',
      payload: {
        eventCategory,
        eventDataObject,
        eventLabel,
        eventType,
        eventValue,
      },
    })
  }

  return fn
}

export const Analytics = {
  /**
   * Взаимодействие с формой на странице
   */
  formLeadRequest: (fireAnalyticsV2('form_lead_request'): any),

  /**
   * Взимодействие с фильтрами
   */
  filters: (fireAnalyticsV2('filters'): any),

  /**
   * Действие на ленде (клик по кнопкам вне форм, попапов)
   */
  page: (fireAnalyticsV2('page'): any),

  /**
   * Взимодействие с попапом
   */
  popup: (fireAnalyticsV2('popup'): any),

  /**
   * Взимодействие с элементами в продуктовой карточке
   */
  productCard: (fireAnalyticsV2('product-card'): any),

  /**
   * Взимодействие с элементами в продуктовой карточке
   */
  shareSocial: (fireAnalyticsV2('share-social'): any),

  /**
   * Взимодействие с элементами в продуктовой карточке
   */
  copyToClipboard: (fireAnalyticsV2('clipboard'): any),

  /**
   * Взимодействие с карточками в блоке Трипваеров
   */
  tripwires: (fireAnalyticsV2('tripwires'): any),
}
