// @flow

import $ from 'jquery'

import { FoxfordService } from 'services/foxford'

import { pendingActions } from '../pending-actions'
import { redirectBack } from '../redirect'

import type { UserTag } from '../tag'
import type { User } from '@foxford/foxford-js-sdk'

export const EXECUTION_PERMISSIONS = {
  ALLOWED: 0,
  FORBIDDEN: 2,
  LOGIN_REQUIRED: 1,
}

/**
 * 0 - false - undefined - функция разрешает дальнейшее выполнение кода onUserSet
 * 1 - true - функция редиректит неавторизованного пользователя на страницу логина, дальнейший код onUserSet не выполняется
 * 2 - функция запрещает выполнять дальнейший код onUserSet
 */
type OnUserSetCallbackReturnType = void | boolean | Promise<any> | $Values<typeof EXECUTION_PERMISSIONS>
export type OnUserSetCallback = (user: User) => OnUserSetCallbackReturnType

/**
 * Заполняет стандартные поля формы данными пользователя при его инициализации.
 * @param {UserTag} [visitTag] Тег, который будет присвоен пользователю (при его отсутствии).
 * @param {OnUserSetCallback} [getExecutionPermission] Функция, в которую передаются данные пользователя из FoxfordService и которая возвращает значение доступности страницы.
 */
export const onUserSet = (visitTag?: UserTag, getExecutionPermission?: OnUserSetCallback): void => {
  const user: User = FoxfordService.user.getCurrentUser()
  const isLoggedIn = user.isLoggedIn()
  let permissionType = EXECUTION_PERMISSIONS.ALLOWED

  if (getExecutionPermission) {
    permissionType = Number(getExecutionPermission(user))
    if (permissionType === EXECUTION_PERMISSIONS.FORBIDDEN) {
      return
    }
  }

  if (!isLoggedIn) {
    if (permissionType === EXECUTION_PERMISSIONS.LOGIN_REQUIRED) {
      redirectBack()
    }

    return
  }

  if (visitTag) {
    pendingActions.addTag(visitTag.id)
  }

  $('input[data-name="Name"], input[data-name="name"]').val(`${user.firstName} ${user.lastName}`)

  $('input[data-name="Phone"], input[data-name="phone"]').val(user.phone && `+${user.phone}`)

  if (user.phoneConfirmed) {
    $('input[data-name="Phone"], input[data-name="phone"]').addClass('is-confirmed') // .prop('disabled', true)
  }

  $('input[data-name="Parent-phone"], input[data-name="parent-phone"]').val(user.parentPhone && `+${user.parentPhone}`)

  if (user.parentPhoneConfirmed) {
    $('input[data-name="Parent-phone"], input[data-name="parent-phone"]').addClass('is-confirmed')
  }

  const inputEmails = document.querySelectorAll('input[data-name="Email"], input[data-name="email"]')
  inputEmails.forEach((element) => {
    if (!(element instanceof HTMLInputElement)) {
      return
    }

    element.value = user.email

    if (user.email && !element.hasAttribute('data-not-disabled')) {
      element.disabled = true // если email указан и атрибута нет, дизейблим инпут
    } else {
      element.disabled = false // если одно из условий не выполнено, инпут активен
    }
  })

  if (user.emailConfirmed) {
    $('input[data-name="Email"], input[data-name="email"]').addClass('is-confirmed')
  }

  $('select[data-name="Grade"], select[data-name="grade"]').each((_, select) => {
    const options = $(select)
      .find('option')
      .map((_, el) => el.value)
      .get()
    // .map(Number) // пустая строка переводится в ноль, поэтому с Дошкольниками работало не корректно

    if (options.includes(String(user.grade.index))) {
      $(select).val(user.grade.index).trigger('change')
    }
  })

  $('input[data-name="First-Name"], input[data-name=Firstname]').val(user.firstName)
  $('input[data-name="Last-Name"]').val(user.lastName)
  $('input[data-name="Middle-Name"]').val(user.middleName)
  $('input[data-name="User-Id"]').val(user.id).prop('disabled', Boolean(user.id))
}
