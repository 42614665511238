// @flow

const generatePhoneQueryParam = () => {
  const phonelInput = document.querySelector('input[data-name="Phone"], input[data-name="phone"]')
  let phone = ''
  if (phonelInput instanceof HTMLInputElement) {
    phone = `phone=${phonelInput.value.replace(/\D/g, '')}&`
  }

  return phone
}

const generateEmailQueryParam = () => {
  const emailInput = document.querySelector('input[data-name="Email"], input[data-name="email"]')
  let email = ''
  if (emailInput instanceof HTMLInputElement) {
    email = `email=${emailInput.value}&`
  }

  return email
}

export const generateQueryParam = (): string => {
  const phone = generatePhoneQueryParam()
  const email = generateEmailQueryParam()

  if (email) {
    return email
  }

  return phone
}
