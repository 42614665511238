// @flow

import { Analytics } from './analytics/analytic-v2'
import { noteNotification } from './notification'

export const copyToClipboard = (dataToCopy: string, notification: string = 'Промокод скопирован') => {
  window.navigator.clipboard.writeText(dataToCopy).then(
    () => {
      if (notification) {
        noteNotification(notification)
      }
    },
    () => {
      if (notification) {
        noteNotification('Что-то пошло не так...')
      }
    }
  )

  Analytics.copyToClipboard('click', 'button')
}
