// @flow

import $ from 'jquery'

import { FoxfordService } from 'services/foxford'
import { FASTDEV_USER_EVENTS } from 'services/user/data'

import { addRegistrationAnalytics } from './analytics'
import { FOOTER_CONTAINER_NAME, HEADER_CONTAINER_NAME } from './constants'
import { initConfirmPhoneAfterSetUser } from './init-confirm-phone'
import { documentReady, lightweihtDocumentReady } from './listeners/document-ready'
import { onUserSet } from './listeners/on-user-set'

import type { Widget } from '@foxford/foxford-js-sdk'

type AnalyticContext = {
  prefix: string,
  module: string,
  payload?: { ... },
}

type ConstructorParamsType = {
  documentReadyListeners: Array<typeof documentReady>,
  onUserSetListener: typeof onUserSet,
  customWidgets: Array<Widget>,
  widgetsAnalyticContext: AnalyticContext,
  areWidgetsNeeded: boolean,
  turnOnMenu: boolean,
}

/**
 * Создает в body div-контейнеры для хедера и футера и возвращает объект
 * для инициализации виджета меню (после передачи его в FoxfordService).
 */
export const createDefaultMenuWidget = (turnOnMenu: boolean = true, options: Object = {}): Widget => {
  if (turnOnMenu && !$(`#${HEADER_CONTAINER_NAME}`).length) {
    const header = document.createElement('div')
    header.setAttribute('id', HEADER_CONTAINER_NAME)
    $('body').prepend(header)
  }

  if (!$(`#${FOOTER_CONTAINER_NAME}`).length) {
    const footer = document.createElement('div')
    footer.setAttribute('id', FOOTER_CONTAINER_NAME)
    $('body').append(footer)
  }

  if (!turnOnMenu) {
    $(`#${HEADER_CONTAINER_NAME}`).remove()
  }

  return {
    name: 'menu',
    options: {
      footer: true,
      footerContainer: $(`#${FOOTER_CONTAINER_NAME}`).get(0),
      hasOneClickMenu: false,
      header: turnOnMenu,
      headerContainer: $(`#${HEADER_CONTAINER_NAME}`).get(0),
      loadFonts: false,
      rootColors: false,
      ...options,
    },
  }
}

/**
 * Отправляет переданный массив виджетов в FoxfordService для отображения их на странице.
 * Если в массиве отсутствует объект виджета меню, то будет создан стандартный.
 * Дока по виджетам: https://app.gitbook.com/o/-LlaQkilti_ARcKW5Lzd/s/-LnwZqUa2yqja2uK1w8Q/obshee/vidzhety-dlya-vneshnikh-stranic
 * @param {Array<Widget>} customWidgets Массив виджетов.
 * @param {AnalyticContext} analyticContext Контекст аналитики.
 */
const initWidgets = (customWidgets: Array<Widget>, analyticContext: AnalyticContext, turnOnMenu: boolean) => {
  const WIDGETS_CONFIG = Object.freeze({
    analyticContext,
    widgets: [],
  })

  customWidgets.forEach((widget) => {
    WIDGETS_CONFIG.widgets.push(widget)
  })

  if (!customWidgets.find(({ name }) => name === 'menu')) {
    WIDGETS_CONFIG.widgets.push(createDefaultMenuWidget(turnOnMenu))
  }

  FoxfordService.initWidgets(WIDGETS_CONFIG)
}

/**
 * @param {Boolean} areWidgetsNeeded Отключает все виджеты полностью
 * @param {Boolean} turnOnMenu Включать верхнее меню
 */
export class App {
  VERSION: string = '1.0.0'
  documentReadyListeners: $PropertyType<ConstructorParamsType, 'documentReadyListeners'> = [documentReady]
  onUserSetListener: $PropertyType<ConstructorParamsType, 'onUserSetListener'> = onUserSet
  customWidgets: $PropertyType<ConstructorParamsType, 'customWidgets'> = []
  widgetsAnalyticContext: $PropertyType<ConstructorParamsType, 'widgetsAnalyticContext'> = { module: '', prefix: 'app' }
  areWidgetsNeeded: $PropertyType<ConstructorParamsType, 'areWidgetsNeeded'> = true
  turnOnMenu: $PropertyType<ConstructorParamsType, 'turnOnMenu'> = false

  constructor({
    documentReadyListeners,
    onUserSetListener,
    customWidgets,
    widgetsAnalyticContext,
    areWidgetsNeeded = true,
    turnOnMenu = true,
  }: $Shape<ConstructorParamsType>) {
    if (documentReadyListeners) {
      this.documentReadyListeners = documentReadyListeners
    }

    if (onUserSetListener) {
      this.onUserSetListener = onUserSetListener
    }

    if (customWidgets) {
      this.customWidgets = customWidgets
    }

    if (widgetsAnalyticContext) {
      this.widgetsAnalyticContext = widgetsAnalyticContext
    }

    this.areWidgetsNeeded = areWidgetsNeeded

    this.turnOnMenu = turnOnMenu

    this.init()

    window.addEventListener(
      FASTDEV_USER_EVENTS.SET_USER,
      () => {
        this.onUserSet()
      },
      { once: true }
    )
  }

  /**
   * Инициализирует JS-логику.
   * Вызывается в конструкторе по историческим причинам (так быть не должно).
   */
  init: () => void = () => {
    this.printEnv()

    // Если виджеты есть, то загрузка app_options происходит после загрузки ассетов виджетов
    if (this.areWidgetsNeeded) {
      // init widgets with fetching app options
      initWidgets(this.customWidgets, this.widgetsAnalyticContext, this.turnOnMenu)
    } else {
      // fetch app options
      FoxfordService.fetchAppOptions()
    }

    this.bindListeners()
  }

  // eslint-disable-next-line class-methods-use-this
  onUserSet: () => void = () => {
    initConfirmPhoneAfterSetUser()
  }

  /**
   * Вызывает все функции из массива documentReadyListeners и регистрирует обработчики событий.
   */
  bindListeners: () => void = () => {
    lightweihtDocumentReady()
    this.documentReadyListeners.forEach((documentReadyFunction) => documentReadyFunction())

    const callOnUserSetListener = () => {
      this.onUserSetListener()
      // TODO всё ломается на первом сет юзере, когда устанавливаем пустого юзера
      // Пока что изменил событие с SET_USER на FETCH_USER. Но в целом эта логика работает не совсем хорошо
      // Поэтмоу нужно будет все это исправить в будущем
      window.removeEventListener(FoxfordService.user.events.FETCH_USER, callOnUserSetListener)
    }

    window.addEventListener(FoxfordService.user.events.FETCH_USER, callOnUserSetListener)

    window.addEventListener(FoxfordService.events.LR_REGISTRATION_SET, () =>
      addRegistrationAnalytics(FoxfordService.user.refreshUser)
    )
  }

  /**
   * Выводит в консоль версию приложения.
   */
  printEnv: () => void = () => {
    console.log(process.env)
  }
}
